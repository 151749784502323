.intervention-overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /*background-color: white;*/
    font-size: 12rem;
}

.intervention-overlay__svg-wrap {
    width: 273rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.intervention-overlay__svg-wrap svg {
    position: absolute;
    cursor: pointer;
    /*width: 289.2rem; !* dit is de orignele maat *!*/
    width: 330rem;
}

.intervention-overlay__text {
    position: relative;
    white-space: pre-wrap;
    font-size: 20rem;
    line-height: 24rem;
    color: #FEE7C5;
    font-weight: bold;
    font-family: 'Kanit', sans-serif;
    text-transform: uppercase;
    text-align: center;
    user-select: none;
    cursor: pointer;
    /*justify-content: center;*/
    display: flex;
    flex-direction: column;
    /*height: 150px;*/
    align-items: center;
}

.intervention-overlay__text__underline {
    /*position: absolute;*/
    border-top: 3rem solid black;
    margin-top: 12rem;
    width: 100rem;
    height: 0;
    position: relative;
}

.intervention-overlay__text__underline svg {
    position: absolute;
    width: 39rem;
    top: 8rem;
    left: 37rem;
}

.intervention-overlay__stay-silent {
    position: absolute;
    width: 100rem;
    /*height: 13.2rem;*/
    /*left: calc(375rem - 50rem);*/
    bottom: 100rem;
    /*background: transparent url(./assets/button-zeg_niks.svg) no-repeat;*/
    font-family: 'Kanit', sans-serif;
    font-size: 15rem;
    text-transform: uppercase;
    text-align: center;
    user-select: none;
    cursor: pointer;
    color: #FEE7C5;
}