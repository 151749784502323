.config-checker {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    font-size: 12rem;
}

.config-checker h1 {
    font-size: 16rem;
}