.scene-player {
    max-width: 100%;
    overflow: hidden;
}

.scene-player__end-screen {
    font-size: 26rem;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scene-player__end-screen__body-wrap {
    position: relative;
    width: 100%;
    height: 670rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scene-player__end-screen__body {
    position: relative;
    margin: 0 53rem;
    text-align: center;
    font-size: 26rem;
    line-height: 31.2rem;
    text-transform: uppercase;
    white-space: pre-line;
    color: #FEE7C5;
    font-weight: bold;
}

.scene-player__end-screen__actions {
    position: relative;
    /*bottom: 0;*/
    height: 91rem;
    display: flex;
    justify-content: center;
}

.scene-player__end-screen__actions button {
    color: #00284C;
    background-color: #33E9FD;
    border: 3rem solid #00284C;
    width: 123.3rem;
    height: 38.5rem;
    font-family: 'Kanit', sans-serif;
    font-size: 18rem;
    font-weight: 700;
    text-align: center;
    margin: 0 8rem;
    text-transform: uppercase;
    /*letter-spacing: -1rem;*/
}



.scene-player__one-liners-container {
    position: relative;
    width: 375rem;
    height: 670rem;
    overflow: hidden;
    pointer-events: none;
}

.scene-player__one-liner-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    animation-duration: 3s;
    animation-name: slideinout;
    visibility: hidden;
    transition: left 0.5s, top 0.5s;
    left: 100%;
    top: -100%;
}

.scene-player__one-liner-card.enter {
    left: 0;
    top: 0;
}

.scene-player__one-liner-card.exit {
    left: -100%;
    top: 100%;
}

.scene-player__leader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
}

.scene-player__leader-text {
    color: #FEE7C5;
    text-align: center;
    font-size: 60rem;
    font-weight: 700;
}

.scene-player__debug_video_label {
    position: absolute;
    bottom: 0;
    font-size: 14rem;
    color: lightgrey;
    text-align: center;
    background-color: #00000066;
    padding: 0 3rem;
}

.scene-player__close {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;

    width: 61rem;
    height: 62rem;

    cursor:pointer;
    background: transparent url(./assets/button-back.svg) no-repeat center center;
    background-size: 26rem 26rem;

    /* Hide the text. */
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

/* hide spinner, a it sometimes fails to hide in iOS */
.vjs-loading-spinner {
    display: none !important;
}