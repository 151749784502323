body {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-wrap {
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.version-info {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 4px;
  font-size: 14rem;
  color: transparent;
}

.version-info-debug {
  /*color: #666666;*/
  color: #f6c82f;
  user-select: none;
  background-color: unset;
  font-size: 14rem;
  /*right: unset;*/
  /*top: unset;*/
  /*left: 0;*/
  /*bottom: 0;*/
}

/* hide focus outline */
*:focus {
  outline: none;
}

/* default for 'generic' buttons */
button {
  font-size: 12rem;
  padding: 2rem;
}
