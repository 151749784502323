.app {
  position: relative;
  width: 375rem;
  height: 670rem;
  overflow: hidden;
}

.state-2 { /* collected one liners */
  overflow: visible;
}

.state-3 { /* info screen */
  overflow: visible;
}

.player-wrap {
  position: relative;
  width: 100%;
  /*max-height: 100%;*/
  height: 100%;
}
.start-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-js {
  position: absolute !important;
}

.scene-selector {
  position: absolute;
  width: 100%;
  height: 100%;
  /*background-color: #ffffff99;*/
}

.scene-selector__loop {
  width: 100%;
}

.scene-selector__buttons {
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.scene-selector__start-label {
  position: absolute;
  left: 262rem;
  top: 242rem;
  width: 45.5rem;
  height: 32rem;
  background: transparent url(./assets/start.svg) no-repeat;
}

.one-liner-button {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  width: 208.3rem;
  height: 79.0rem;
  position: absolute;
  top: 593rem;
  left: 174.7rem;
  background-size: contain;
  cursor:pointer;
  background: transparent url(./assets/button-oneliners.svg) no-repeat;
  transition: top 1s;

  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.state-2 .one-liner-button {
  background: transparent url(./assets/button-oneliners-close.svg) no-repeat;
  top: 25rem;
}

.share-button {
  width: 150.3rem;
  height: 98.0rem;
  position: absolute;
  bottom: -34rem;
  left: 68.5rem;
  background: url(./assets/button-share.svg) no-repeat;
  background-size: contain;
  cursor:pointer;
  border:none;
  text-indent: -9000px;
}

.app__title-background-gradient {
  width: 100%;
  height: 180.3rem;
  background-image: url("assets/title-gradient.png");
  background-position: 0 0;
  background-size: cover;
  position: relative;
}

.app-title {
  position: absolute;
  /*top: 30.8rem;*/
  top: 21.8rem;
  left: 79.4rem;
  text-transform: uppercase;
  user-select: none;
  font-size: 28rem;
  line-height: 33.6rem;
  font-family: 'Kanit', sans-serif;
  font-weight: bold;
  color: #FEE7C5;
  cursor:pointer;
}

.speaker {
  position: absolute;
  width: 71.7rem;
  height: 136.0rem;
  top: 26.4rem;
  left: -9.6rem;
  background: url(./assets/speaker.svg) no-repeat;
  cursor:pointer;
}

.new-one-liners-notification {
  position: absolute;
  bottom: 60rem;
  right: 6.6rem;
  width: 33.1rem;
  height: 33.1rem;
  border-radius: calc(33.1rem / 2);
  color: #F90248;
  background-color: #FBFF69;
  font-size: 17.5rem;
  font-family: 'Kanit', sans-serif;
  font-weight: bold;
  text-align: center;
  line-height: 33.1rem;
  user-select: none;
}

.app__one-liner-overview-wrap {
  position: absolute;
  top: 670rem;
  width: 100%;
  transition: top 1s;
}

.state-2 .app__one-liner-overview-wrap {
  top: 101rem;
}

.app__collected-all {
  position: absolute;
  width: 360rem;
  height: 241rem;
  bottom: -241rem;
  right: -360rem;
  background: url(./assets/collected-all.svg) no-repeat;
  cursor:pointer;
  transition: bottom 1s, right 1s;
}

/* hide on oneliner overview screen, and info screen, because that screen can scroll and would otherwise show the 'collected-all' image */
.state-2 .app__collected-all, .state-3 .app__collected-all {
  display: none;
}

.app__collected-all.show {
  bottom: -2rem;
  right: -5rem;
}

.app__reset-all {
  position: absolute;
  top: 2rem;
  right: 42rem;
}


.intro-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/intro.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 594.5808rem 719.269rem;
}