.start-button {
    position: absolute;
    width: 42.05rem;
    height: 42.05rem;
    box-sizing: border-box;
}

.start-button button {
    position: absolute;
    width: 42.05rem;
    height: 42.05rem;
    /*background:url(./assets/button-start.svg) no-repeat;*/
    cursor:pointer;
    border:none;
    background: none;

    /* Hide the text. */
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.start-button svg {
    position: absolute;
    width: 42.05rem;
    height: 42.05rem;
}

