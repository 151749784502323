.info-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: white;
    font-size: 18rem;
    overflow-x: hidden;
}

.state-3 .app-title {
    color: #00284C;
}

.info-screen__body {
    position: relative;
    padding-top: 154rem;
    padding-left: 30.4rem;
    padding-right: 30.4rem;
    color: #00284C;
}

.info-screen__body h3 {
    font-size: 18rem;
    line-height: 24rem;
    font-weight: 500; /* medium */
}

.info-screen__body h2 {
    font-size: 20rem;
    line-height: 24rem;
    font-weight: 500; /* medium */
    margin-top: 48rem;
    margin-bottom: 24rem;
}

.info-screen__body p {
    font-size: 18rem;
    line-height: 24rem;
    font-weight: 300; /* light */
}

.info-screen__close {
    width: 15rem;
    height: 15rem;
    position: absolute;
    top: 20.8rem;
    right: 20rem;
    background: url(./assets/button-close.svg) no-repeat;
    background-size: contain;
    cursor:pointer;
    border:none;
    text-indent: -9000px;
}