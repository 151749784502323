.one-liner-overview {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding-bottom: 50rem;
    padding-top: 20rem;
    background-color: white;
    font-size: 18rem;
}

.one-liner-overview__collected {
    display: inline-block;
    font-size: 24rem;
    font-family: 'Kanit', sans-serif;
    font-weight: bold;
    margin-left: 30rem;
}

.one-liner-overview__intro {
    box-sizing: border-box;
    width: 100%;
    padding: 5rem 30rem;
    text-align: center;
    font-weight: 500; /* Medium 500 */
    margin-bottom: 0;
    margin-top: 0;
}

.one-liners-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}