.one-liner {
    position: relative;
    box-sizing: border-box;
    width: 302rem;
    min-height: 88rem;
    margin-top: 20rem;
    border: 25rem solid transparent;
    /*border-image: url(assets/one-liner-bg-4.svg) 35 fill repeat;*/
    border-image: url(assets/one-liner-bg-4.svg) 70 fill repeat;
    /*border-image-source: */
}
.one-liner__label {
    text-align: center;
    white-space: pre-wrap;
    font-size: 26rem;
    line-height: 31.2rem;
    font-family: 'Kanit', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    user-select: none;
    /*padding: 0;*/
    padding: 0 0 20rem 20rem;
}
.one-liner-wrap {
    position: relative;
    padding-left: 8rem;
}

.one-liner-wrap.one-liner-overview-not-collected {
    padding-left: 0;
    margin-left: -9rem;
}

.one-liner-dotted-backdrop {
    box-sizing: border-box;
    width: 283rem;
    text-align: center;
    white-space: pre-wrap;
    font-size: 26rem;
    line-height: 31.2rem;
    font-family: 'Kanit', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    user-select: none;
    /*padding: 0;*/
    padding: 21rem 24rem;

    border: 3rem dashed black;
    color: white; /* makes text invisible */
    position: absolute;
    top: 28rem;
    left: 0;
}